import React from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Link, Typography } from "@mui/material";

const AstronautSponsor = ({ sponsor }) => {
  return (
    <Container href={sponsor.url} target="_blank" rel="noreferrer">
      <img src={sponsor.image} alt="Sponsor Logo" />
      <Typography variant="label" sx={{ mt: 1 }}>
        {sponsor.name}
      </Typography>
    </Container>
  );
};

const Container = styled(Link)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 8,
  padding: 16,
  marginTop: 16,
  minWidth: 200,
  maxWidth: 300,
  border: `1px solid #FFFFFF99`,

  textDecoration: "none",
  color: "white",

  "& img": {
    width: 120,
  },
});

export default AstronautSponsor;
