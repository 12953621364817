import React from "react";

// MATERIAL UI
import { Typography, Box, Button } from "@mui/material";

// COMPONENTS
import Layout from "../components/Layout";
import Volunteer from "../components/sponsors/Volunteer";
import AstronautSponsor from "../components/sponsors/AstronautSponsor";
import CommanderSponsor from "../components/sponsors/CommanderSponsor";

import { getCompanies } from "../utils/utils";

const Sponsors = () => {
  return (
    <Layout pageTitle="Sponsors" metaTitle="Our sponsors">
      <Typography variant="h3">Crew Commander sponsors</Typography>
      {getCompanies(["The Communion"]).map((c) => {
        return <CommanderSponsor sponsor={c} />;
      })}
      <Typography variant="h3" sx={{ mt: 12 }}>
        Astronaut Crew Member sponsors
      </Typography>
      {getCompanies(["Company Name"]).map((c) => (
        <AstronautSponsor sponsor={c} />
      ))}
      <Typography variant="h3" sx={{ mt: 12 }}>
        Space Center Specialists sponsors
      </Typography>
      {voulteers.map((v) => (
        <Volunteer sponsor={v} />
      ))}
      <Typography variant="h2" sx={{ mt: 15, mb: 4 }}>
        Become a Sponsor
      </Typography>
      {sponsorPlans.map((sp) => {
        return (
          <Box sx={{ maxWidth: 700 }}>
            <Typography variant="h4">{sp.name}</Typography>
            <Typography variant="label">
              ${sp.ammount} a month{" "}
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                href={sp.paymentLink}
                target="_blank"
                rel="noreferrer"
              >
                Select
              </Button>
            </Typography>
            <Typography sx={{ mb: 4 }}>{sp.text}</Typography>
          </Box>
        );
      })}
    </Layout>
  );
};

const voulteers = [
  {
    name: "Victor Gonzalez",
    imageSrc: "https://avatars.githubusercontent.com/u/60766593?v=4",
    target: "https://github.com/GonzAVic",
  },
];

const sponsorPlans = [
  {
    name: "Space Center Volunteer",
    ammount: 1,
    text: "Thanks! It really means a lot",
    paymentLink: "https://buy.stripe.com/14keWtciQ5Pbdnq9AC",
  },
  {
    name: "Space Center Specialist",
    ammount: 3,
    text: 'Wow, thanks! You get your github/portfolio profile in the "Space Center Specialist"',
    paymentLink: "https://buy.stripe.com/00g6pX82A91n97a3cf",
  },
  {
    name: "Astronaut Crew Member",
    ammount: 7,
    text: 'You Rock! You get your github/portfolio profile in the "Astronaut Crew Member" Cards!',
    paymentLink: "https://buy.stripe.com/fZe15D2IgdhD4QU9AE",
  },
  {
    name: "Crew Commander",
    ammount: 12,
    text: 'You\'re the boss! You get your github/portfolio profile in the "Crew Commander" Cards and your project/portfolio link is going to be added to the "Sponsors" collections when new users install Pau',
    paymentLink: "https://buy.stripe.com/aEUdSp6Yw6TfcjmaEJ",
  },
];

export default Sponsors;
