import React from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography, Link } from "@mui/material";

const CommanderSponsor = ({ sponsor }) => {
  return (
    <Container href={sponsor.url} target="_blank" rel="noreferrer">
      <TextContainer>
        <img src={sponsor.image} alt="Sponsor Logo" />
        <Typography variant="h3" sx={{ mb: 2 }}>
          {sponsor.name}
        </Typography>
        <Typography>{sponsor.description}</Typography>
      </TextContainer>
    </Container>
  );
};

const Container = styled(Link)({
  display: "inline-block",
  width: "85vw",
  maxWidth: 400,
  border: `1px solid #FFFFFF99`,
  borderRadius: 6,
  marginTop: 16,
  textDecoration: "none",
  color: "#FFFFFF",

  "& img": {
    height: 60,
    maxWidth: 300,
    margin: "auto",
    display: "block",
    marginBottom: 30,
  },
});

const TextContainer = styled("div")({
  textAlign: "center",
  padding: 20,
});

export default CommanderSponsor;
