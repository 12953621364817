import React from "react";

import { Avatar, Tooltip, Link } from "@mui/material";

const Volunteer = ({ sponsor }) => {
  return (
    <Link
      href={sponsor.target}
      target="_blank"
      rel="noreferrer"
      sx={{ width: "fit-content", display: "inline-block", mt: 2, mr: 1 }}
    >
      <Tooltip title={sponsor.name}>
        <Avatar
          className="clickable"
          alt={sponsor.name}
          src={sponsor.imageSrc}
        />
      </Tooltip>
    </Link>
  );
};

export default Volunteer;
